.shadow-container{
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.03);
}
.att-img{
    display: flex;
    width: 56px;
    height: 56px;
    border-radius: 8px;
    background-size: cover;
    background-position: center;
    padding: 17px;
}

.h6-text{
    margin-left: 10px;
    color: #959499;
}
.h4-text{
    margin-left: 10px;
    font-weight: 700;
}