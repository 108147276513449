.reset-successfully-bg{
    background-image: url('../../../Assets/Images/reset-successfullyl-bg.png');
    height: 100vh;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.background-successfully{
    background-color: #fff;
    text-align: center;
}

.reset-successfully-bg h1{
  color: #343434 ;
  font-size: 32px;   
}
.reset-successfully-bg p{
    width: 375px;
    font-size: 16px;
    margin-bottom: 30px;
}

.reset-successfully-bg img{
    margin-bottom: 20px;
}


.reset-successfully-bg button{
    background-color: #343434 !important;
    color: #ffffff;
    border:none;
    min-width: 230px;
    font-size: 16px;
    text-transform: uppercase;
    line-height: 40px;
    padding: 0px;
    padding: 2px 15px 0px;
}