.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.add-employee {
    background-color: #2BC8CB !important;
    border: none !important;
    align-items: center;
    justify-content: center;
    height: 40px;
}

.add-employee:hover {
    background-color: #2BC8CB;
}

.emp-detail-card .dropdown-toggle::after{
    display: none !important;
}

.emp-detail-card button {
    border:none !important;
    padding: 0px;
}

.emp-detail-card .dropdown-menu{
    position: absolute !important;
    inset: 0px auto auto -150px !important;
    border: none;
    background-color: #ffffff;
    box-shadow: 0px 0px 15px rgb(213, 210, 210);
}

.emp-detail-card  .btn.show{
    background-color: #ffffff !important;
    color: #343434 !important;
    border: 1px solid #ffffff !important;
    box-shadow: none !important;
}

.emp-detail-card .btn:focus-visible{
    box-shadow:none !important;
}

.add-employee img{
    margin-bottom: 2px; 
}
.employee-detail-box{
    display: flex;
    padding: 8px;
}

.emp-img img{
    height: 56px;
    width: 56px;
    border-radius: 8px;
}

.p-text-container{
    font-size: medium;
    color: #2BC8CB;
    font-weight: 700;
}

.emp-status-active-color{
    color: #0bad0b;
    font-weight: 500;
    background: #13f5132b;
    width: fit-content;
    padding: 2px 8px;
    border-radius: 4px;
}

.emp-status-inactive-color{
    color: red;
    font-weight: 500;
    background: #ff000017;
    width: fit-content;
    padding: 2px 8px;
    border-radius: 4px;
}

.empty-employee-list{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh;
}



@media only screen and (max-width: 576px){
    .add-employee img{
        width: 20px;
        height: 20px;
    }
}
