.barContainer{
    display: block;
    background-color: white;
    padding-inline: 20px;
    padding-block: 30px;
    height: 100vh
}
.sidebar-active{
    display: flex;
    background-color: #F0FBFB;
    margin-top: 25px;
    height: 40px;
    width: 40px;
    padding: 10px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}
.sidebar-pending{
    display: flex;
    margin-top: 25px;
    height: 40px;
    width: 40px;
    padding: 10px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.side-bar{
    position: fixed;
    padding-top: 40px;
}
.chat-notification-sidebar{

    position: relative;
}

.badge-siderbar-chat-counter{
    position: absolute;
    top: 160px ;
    left: 60px ;
}
@media only screen and (max-width: 992px){
    .barContainer {
        padding-inline: 12px;
    }
}


@media only screen and (max-width: 576px) {
   
    .barContainer {
        padding-inline: 10px;
    }

    .sidebar-pending {
        margin-top: 16px;
    }
    .sidebar-active {
        height: 36px;
        width: 36px;
        padding: 8px;
    }
    .sidebar-pending{
        height: 36px;
        width: 36px;
        padding: 8px;
    }

    .sidebar-active{
        margin-top: 16px;
    }
  }