.navigation{
    background: white;
}

.navigation .navbar{
    padding: 8px 30px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 999;
}

.profile{
    width: 36px;
    height: 36px;
    background-position: center center;
    cursor: pointer;
    background-size: cover;
}

.admin-profile-dropdown .profile{
    width: 36px;
    height: 36px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: none;
}

.admin-profile-dropdown .profile img{
    width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.title{
    display: flex;
    align-items: center;
    margin-right: 10px;
}
.navigation-icon a{
    margin-right: 20px;
}

.navigation-icon a img{
    width: 32px;
}

.admin-profile-dropdown .dropdown-menu{
    position: absolute !important;
    inset: 50px auto auto -120px !important;
    border: none;
    background-color: #ffffff;
    box-shadow: 0px 0px 15px rgb(213, 210, 210);
}

.admin-profile-dropdown .dropdown-toggle::after{
    display: none !important;
}

#sidebar-toggle{
    display: none;
    border: 0;
    border-radius: 4px;
    border: 1px solid #3434340d ;
    background: white;
    width: 28px;
    height: 24px;
}

#sidebar-toggle img{
    width: 20px;
    height: 20px;
}

@media only screen and (max-width: 992px) {   
    #sidebar-toggle{
        display: block;
    }
    .side-bar {
        transform: translateX(-100px);
        -webkit-transform: translateX(-100px);
        transition: all 0.3s;
    }
    .openSidebar div#sidebarnav {
        transform: translateX(0);
    }
    .openSidebar .inner-contain {
        padding-left: 70px;
    }

    .sidebar-button{
        display: flex;
        align-items: center;
    } 
    .sidebar-button button{
        width: 24px;
        height: 24px;
        border: 0px;
        background-color: #343434;
        border-radius: 4px;
    }
    .sidebar-button button img{
        width: 20px;
        height: 20px;
    }
    .header-profile-name{
        display: none;
    }
    
}


@media only screen and (max-width: 567px){
    
.navbar-nav h6{
    display: none;
}

.openSidebar .inner-contain {
   padding-left: 60px;
}

}
