.header-class {
    background-color: #F0FBFB;
}

.header-class th {
    font-weight: 400;
}

.attendance-search .input-group-text {
    background-color: #ffffff !important;
    border-right: none !important;
}

.attendance-search .form-control {
    border-left: none !important;
    padding: 0.375rem 0.2rem;
}

.search-class {
    margin-block: 15px;
}

.date-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.sr-only {
    display: none;
}

.date-time-input input {
    height: 40px;
    width: 250px;
}

.attendance-table .react-bootstrap-table .table-striped>tbody>tr:nth-of-type(odd)>* {
    background-color: #ffffff !important;
    --bs-table-accent-bg: none;
}

.attendance-table .react-bootstrap-table .table td {
    background-color: #ffffff !important;
    vertical-align: middle;
}

.attendance-table .react-bootstrap-table-page-btns-ul .active>.page-link,
.page-link.active {
    background-color: rgb(211, 243, 255) !important;
    border: none !important;
    color: black;
    height: 39px;
}

.attendance-table .react-bootstrap-table-pagination .dropdown-toggle {
    background-color: rgb(211, 243, 255) !important;
    border: none !important;
    color: black;
    height: 39px;
    margin-right: 4px;
}

@media only screen and (max-width: 992px) {
    .entry-date {
        position: absolute;
        right: 15px;
    }
}


@media only screen and (max-width: 576px) {
    .attendance-table .react-bootstrap-table table {
        width: 750px;

    }

    .attendance-table .react-bootstrap-table {
        overflow-x: scroll;
        margin-bottom: 20px;
    }

    .date-container {
        flex-direction: column;
    }

    .date-container label {
        width: 100%;
    }

    .date-time-input input {
        width: 100%;
    }

    .attendance-table {
        margin-top: 16px;
    }

    .entry-date {
        position: initial;
    }

    .employee-date-container {
        padding: 12px 0px !important;
        width: 100%;

    }

    .employee-date-container .react-date-picker {
        right: 0px;
        display: block;
        width: 100%;
    }

    .employee-date-container .react-date-picker__wrapper {
        width: 100%
    }
}