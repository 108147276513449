
.employeeProfile-component{
    color: #2BC8CB;
    font-weight: 600;
    font-style:normal;
}
.employee-label{
    font-size: 14px;
    font-weight: 400;
    color: #959499;
}
.cover-photo .profile-img{
    width: 134px;
    height: 120px;
    background-color: #979797;
    border-radius: 8px;
    background-size: cover;
    background-position: center;
}

.profile-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.remove-button{
    background-color: #F6F6F6;
    border: 1px solid #979797;
    color: #343434;
}
.remove-button:hover{
    background-color: #F6F6F6;
    border: 1px solid #979797;
    color: #343434;
}
.edit-button{
    background-color: #343434 !important;
    border: none !important;
    color: white !important;
    height: 40px;
    display: flex;
    align-items: center;
}

.edit-button img{
    height: 20px;
    width: 20px;
    margin-bottom: 1px;
    margin-right: 4px;
}

.edit-button-1{
    background-color: #343434 !important;
    border: none !important;
    color: white !important;
    height: 40px;
    display: flex;
    align-items: center;
    display: none;
}

.edit-button-1 img{
    height: 20px;
    width: 20px;
    margin-bottom: 1px;
    margin-right: 4px;
}


.cover-photo{
    background-image: url("../../../../Assets/Images/cove_photo.png");
    height: 190px;
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    position: relative;
}

.profile-header-contain{
    display: flex;
    position: absolute;
    bottom: -50px;
    width: 100%;
    align-items: flex-end;
    padding-left: 20px;
}

.profile-employee-info{
    padding-left: 20px;
    margin-top: 100px;
}
.profile-employee-doc{
    padding-left: 20px;
    margin-top: 20px;
}

.profile-employee-doc h4{
    margin-bottom: 10px;
}
.profile-doc{
    width: 120px;
    height: 120px;
    background-color: #979797;
    border-radius: 8px;
    margin-right: 10px;
    background-size: cover;
    background-position: center;
}

.profile-button{
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 768px){
    .cover-photo .profile-img {
        width: 134px;
        height: 110px;
    }
}

@media only screen and (max-width: 576px){
    
    .profile-employee-info {
        padding-left: 8px;
        margin-top: 80px;
    }
    .cover-photo .profile-img{
        height: 80px;
        width: 120px;   
    }
    .cover-photo {
       
        height: 120px;
        border-radius: 8px;
    }
    .profile-header-contain {
        padding-left: 12px;
    }
    .edit-button{
        display: none;
    }
    .employee-edit-section{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .edit-button-1 {
        background-color: #343434 !important;
        border: none !important;
        color: white !important;
        height: 32px;
        display: flex;
        align-items: center;
        display: block;
        font-size: 12px;
    }
    
    .edit-button-1 img{
        height: 16px;
        width: 16px;
        margin-bottom: 1px;
        margin-right: 4px;
    }
    }