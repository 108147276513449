
.not-found img{
    width: 400px;
}
.not-found {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
}