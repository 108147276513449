
h4{
    font-weight: 900;
}

.profile-header{
    display: flex;
    background-color: white;
    padding: 30px;
    border-radius: 16px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.03);
}

.profile-inner-header{
    display: flex;
    padding: 10px 20px;
    align-items: center;
    width: 100%;
}
.edit-button{
    background-color: #2BC8CB;
    border: none;
}
.profile-container{
    padding: 30px;
}

.profile-detail h5{
    font-size: 24px;
    font-weight: 600;
}

.profile-img{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 130px;
    background-color: rgb(148, 227, 247);
    border-radius: 12px;
    position: relative;
}



.profile-detail h6{
    color: #979797;
    font-weight: 400;
}

.profile-detail p{
    font-size: 16px;
    font-weight: 600;
}

.profile-img form{
    width: 24px;
    height: 24px;
    background-color: #2BC8CB;
    opacity: 0;
}

.profile-img input{
    width: 24px;
    height: 24px;
}


.admin-profile-detail{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.edit-profile-admin{
    background-color: #2BC8CB !important;
    padding: 6px 16px;
    color: #fff !important;
    font-weight: 500 !important;
    display: flex;
    align-items: center;
    border-radius: 8px;
}


.profile-tabs{
    width: 100%;
}

.profile-tabs .react-tabs__tab-list {
    border-bottom: none;
}
.profile-tabs .react-tabs__tab {
    border: none;
    border-bottom: 2px solid transparent;
}
.profile-tabs .react-tabs__tab--selected {
    border-color: #2bc8cb;
    color:#2bc8cb;
    font-weight: 600;
}
.profile-tabs .react-tabs__tab{
    padding: 15px 10px;
}   


.profile-tabs .react-tabs__tab img{
    margin-bottom: 3px;
    margin-right: 4px;
}
.profile-tabs .react-tabs__tab:focus:after{
    content: none;
}
.profile-tabs .react-tabs__tab-list{
    border-bottom: 2px solid #EFEFEF;
}

.basic-subscription{
    border:1px solid #F4F4F4;
    border-radius: 12px;
}
.basic-grey-bg{
    background-color: #F4F4F4;
    padding: 20px 20px 8px 20px;
    border-radius: 12px;
}
.basic-grey-bg .badge {
    font-size: 14px;
    background-color: #343434 !important;
    padding: 8px 16px;
    border-radius: 4px;
    margin-bottom: 8px;
}

.basic-grey-bg h1{
    font-weight: 800;
}

.basic-grey-bg p{
    font-size: 16px;
    line-height: 20px;
    color: #979797;
}

.basic-subscription-details ul li{
    list-style-type: none;
    font-size: 16px;
    margin-bottom: 8px;
}
.basic-subscription-details ul{
    padding: 0px;
}
.basic-subscription-details ul li img {
    margin: 0px 8px 4px 0px;
}

.basic-subscription-details{
    padding: 16px;
}

.basic-subscription-details button{
    width: 100%;
    height: 48px;
    background-color: #2BC8CB;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}
.basic-subscription-details .btn:hover{
    width: 100%;
    height: 48px;
    background-color: #2BC8CB;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}

.pro-bg{
    background-color: #FFF7EF;
    padding: 20px 20px 8px 20px;
    border-radius: 12px;
}

.pro-bg .badge{
    font-size: 14px;
background-color: #FDA755 !important;
padding: 8px 16px;
border-radius: 4px;
margin-bottom: 8px;
}
.pro-bg h1 {
    font-weight: 800;
}
.pro-bg p{
    font-size: 16px;
    line-height: 20px;
    color: #979797;
}
.pro-subscription{
    border: 1px solid #F4F4F4;
    border-radius: 12px;
}

.pro-subscription button {
    width: 100%;
    height: 48px;
    background-color: #343434;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}

.pro-subscription .btn:hover{
    width: 100%;
    height: 48px;
    background-color: #343434;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}


.basic-Standard-bg {
    background-color: #ECF1FF;
    padding: 20px 20px 8px 20px;
    border-radius: 12px;
}

.basic-Standard-bg  .badge {
    font-size: 14px;
    background-color: #5170C7 !important;
    padding: 8px 16px;
    border-radius: 4px;
    margin-bottom: 8px;
}

.basic-Standard-bg h1 {
    font-weight: 800;
}

.basic-Standard-bg p {
    font-size: 16px;
    line-height: 20px;
    color: #979797;
}

.Standard-subscription{
    border: 1px solid #F4F4F4;
    border-radius: 12px;
}

.Standard-subscription button {
    width: 100%;
    height: 48px;
    background-color: #343434;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}
.Standard-subscription .btn:hover{
    width: 100%;
    height: 48px;
    background-color: #343434;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}

.basic-grey-bg sub{
    font-size: 14px;
    font-weight: 300;
    color:#979797;
}
.pro-bg sub{
    font-size: 14px;
    font-weight: 300;
    color:#979797;
}
.basic-Standard-bg sub{
    font-size: 14px;
    font-weight: 300;
    color:#979797;
}


.profile-edit-container h6{
    color: #979797;
    font-weight: 300;
    padding-top: 12px;
    padding-inline: 10px;
    text-decoration: underline;
    cursor: pointer;
}
.edit-profile-admin img{
    margin-right: 8px;
    width: 20px;
}

.profile-edit-container button{
    background-color: #EFEFEF;
    display: flex;
    margin-top: 8px;
    width: 100%;
    justify-content: center;
    font-size: 14px;
}

.profile-edit-container button:hover{
    background-color: #EFEFEF;
}

.profile-edit-container img{
    margin-right: 8px;
    width: 20px;
}

.profile-info-call{
    display: flex;
}

/************media query*************/



@media only screen and (max-width: 1200px){
    .profile-header {
        padding: 20px;
    }

}

@media only screen and (max-width: 992px){
    .subs-box{
        display: flex;
        justify-content: center;
    }
   
    .Standard-subscription{
        width: 50%;
    }

    .admin-profile-detail{
        width: 100%;
    }
    .profile-img{
        width: 155px;
    }
    .edit-profile-admin {
        font-size: 14px;
    }
    .edit-profile-admin img {
        width: 20px;
    }

    .profile-inner-header {
        padding: 10px 0px 20px 10px;
    }
}

@media only screen and (max-width: 576px){
    .profile-img {
        height: 90px;
    }
    .profile-inner-header {
        padding: 0px 8px;
    }
    .profile-detail img{
        width: 20px;
        height: 20px;
    }
    .profile-tabs .react-tabs__tab {
        padding: 15px 4px;
    }
    .Standard-subscription{
        width: 100%;
    }
    .admin-profile-detail {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: column;
    }
    .profile-header {
        padding: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .profile-detail{
        display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px 0px 6px 0px;
    }

    .profile-img{
        width: 100px;
    }
    .profile-edit-container {
        display: flex;
        justify-content: center;
    }

    .profile-edit-container button {
        width: inherit;
        margin-right: 12px;
    }
    .profile-detail p{
        font-size: 14px;
    }
}


@media only screen and (max-width: 280px){
    .navbar-brand img{
        width: 90px;
    }
    .navigation-icon a{
        margin-right: 8px;
    }
    .profile-info-call {
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .profile-edit-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}