input {
    padding: 12px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #EFEFEF;
    align-items: center;
}

input :hover {
    border: 1px solid #EFEFEF;
}

.errorMessage {
    color: red;
    font-size: small;
}

.add-button {
    background-color: #2BC8CB !important;
    border: none !important;
}

.add-button:hover {
    background-color: #2BC8CB !important;
}

.select {
    width: 95%;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #EFEFEF;
    align-items: center;
}

/* 
.add-emp-form input{
    height: 56px;
} */

.add-emp-form input:focus-visible {
    outline: 2px solid #2BC8CB;
    border-radius: 3px;
}

.add-emp-form select:focus-visible {
    height: 58px;
    border: 2px solid #2BC8CB;
}

.add-emp-form .css-t3ipsp-control {
    height: 58px;
    border: 2px solid #2BC8CB !important;
    box-shadow: none !important;
}

.add-emp-form .css-t3ipsp-control:hover {
    height: 58px;
    border: 2px solid #2BC8CB !important;
}

.add-emp-form .css-13cymwt-control {
    height: 58px;
    border-color: #EFEFEF;
}

.add-emp-form .css-qbdosj-Input {
    height: 42px;
    margin: 0px !important;
}

.add-emp-form .css-1u9des2-indicatorSeparator {
    display: none;
}

input[type="date" i] {
    color: rgb(133, 133, 133);
}

.form-floating {
    height: 80px;
}

.date-picker form {
    height: 100%;
}

.date-picker input {
    height: 100%;
}

.form-floating .svg-inline--fa {
    position: absolute;
    right: 20px;
    bottom: 42px;
}

.date-picker-container {
    position: relative;
    margin-bottom: 20px;
}

.floating-label {
    position: absolute;
    top: 2px;
    left: 18px;
    color: #999;
    font-size: 13px;
    transition: all 0.2s ease-in-out;
    pointer-events: none;
}




.date-picker-class .react-date-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: thin solid #ced4da !important;
    border-radius: 5px;
    padding-inline: 15px;
    height: 58px;
}

.date-picker-container .react-date-picker__calendar {
    width: 100% !important;
    border: none;
    border-radius: 16px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.08);
}

.date-picker-container .react-calendar {
    width: 100% !important;
}

.react-calendar__tile--now {
    background-color: #2BC8CB !important;
    color: #ffffff;
}

.date-picker-container .react-date-picker{
    width: 100%;
}

@media only screen and (max-width: 992px) {
    input {
        width: 100%;
    }

    .button-container button {
        width: 100%;
    }

}


@media only screen and (max-width: 576px){


  .date-picker-container .react-date-picker__calendar{
    width: 100% !important;
  }
}