
.form-inner-container{
    background-image: linear-gradient(176.64deg, #1D2733 -37.77%, #2BC8CB 189.82%);
    height: 100vh;
    margin: 35px;
    height: calc( 100vh - 70px);
    position: relative;
    border-radius: 20px;
}

.form-inner-container img{
    padding: 20px;
}
.form-text-container{
    padding: 110px 28px 0px;
}

.form-text-container h2{
    color: white;
    font-size: 32px;
    text-transform: capitalize;
}

.form-text-container h6{
    color: white;
    font-size: 14px;
    font-weight: 300;
    width: 80%;
    line-height: 20px;
}
.auth-bg{
    background-image: url('../../Assets/Images/auth_bg.png');
    background-position: center;
    height: 50vh;
    position: absolute;
    bottom: 0px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}

.form-container{
    position: relative;
    width: 100%;
}

.auth-img{
    position: absolute;
    top: 0px;
    right:0px;
}

.auth-img img{
    width: 165px;
}

.auth-form{
    display: flex;
    margin-top: 40px;
    height: calc( 100vh - 70px);
    align-items: center;
    justify-content: center;
    -webkit-user-select: none;
}

.auth-form form{
    width: 50% !important;
}

.auth-form h6{
    color: #979797;
    font-size: 15px;
    font-weight: 300;
}

.input-container input{
    height: 56px;
}


@media only screen and (max-width: 1200px) {
    
    .form-text-container h6 {
        width: 100%;
    }
    .auth-form form{
        width: 60% !important;
    }
  }


  
@media only screen and (max-width: 992px) {
    
    .form-inner-container {
        height: calc( 50vh - 35px);
        margin: 35px 35px 0px 35px;
    }
    .auth-form {
        height: calc( 50vh - 0px);
        margin-top: 0px;
    }
    .form-text-container {
        height: 20vh;
    }
    .auth-bg {
        height: 25vh;
    }
    .form-text-container h6 {
        font-size: 16px;
    }
    input{
        width: 100%;
    }
    .form-text-container {
        padding: 40px 28px 0px;
    }
  
  }


  @media only screen and (max-width: 767px){
   .form-inner-container{
    display: none;
   } 
   .auth-form {
    height: calc( 100vh - 0px);
    margin-top: 0px;
}
.auth-form form{
    width: 80% !important;
}

  }

  @media only screen and (max-width: 520px){
    .auth-form{
        padding: 20px;
    }
    .create-password h1{
        font-size: 24px;
    }
    .auth-form form{
        width: 90% !important;
    }
  }