.employee-card-list{
    padding: 12px;
    border-radius: 6px;
    margin-right: 8px;
    border-left: 4px solid #ffffff;
    cursor: pointer;
}

.tab-container{
    width: max-content !important;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
    color: #2BC8CB !important;
    border: none !important;
    border-color: none !important;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active :hover{
    border: none !important;
}

.nav-link{
    color: gray !important;
}


.notification-popup .nav-tabs .nav-link.active{
    background-color: #F0FBFB;
    height: 34px;
    border-radius: 4px;
}



.notification-popup .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    isolation: isolate;
    border-color: #ffffff !important;
}

.notification-popup .nav-tabs{
    border-bottom:none;
}


.notification-popup .nav-tabs .nav-link{
    height: 34px;
}


.tab-container li .active::before{
    content: url('../../Assets/Images/document.svg');
    margin-right:4px ;
}


.notification-popup .nav-tabs .nav-link{
    display: flex;
}

.clear-notification-button{
    position: absolute;
    top: 10px;
    right: 50px;
    background-color: #2BC8CB !important;
    border: none !important;
    height: 33px;
}

ˇ.notification-popup .offcanvas-header .btn-close{
    margin-bottom: 0px !important;
}

.notification-popup{
    --bs-offcanvas-width: 450px !important;
}