.modal-view{
    display: flex;
    align-items: center;
    justify-content: center;
}
.icon-view{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 80px;
    background-color: #F9F9F9;
    border-radius: 50px;
}
.text-h6{
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #979797;
}

.cancel-button{
    background-color: white !important;
    border: 1px solid #EFEFEF !important;
    color: black !important;
}

.cancel-button:hover{
    background-color: white !important;
    border: 1px solid #EFEFEF !important;
    color: black !important;
}

.remove-button-content{
    background-color:#2BC8CB !important;
    border: none !important;
    color: white;
    cursor: pointer;
}

.remove-button-content:hover{
    background-color: #2BC8CB !important;
    border: none !important;
    color: white;
    cursor: pointer;
}