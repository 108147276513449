.input-container{
    margin-top: 20px;
    width: 100%;
    position: relative;
}

.input-container .svg-inline--fa{
    position: absolute;
    right: 20px;
    top: 20px;
}



.create-password p{
    color: #979797;
    font-weight: 300;
}
@media only screen and (max-width: 992px){
    .button-container button {
        width: 100%;
    }
}