.chat-employee-list {
    padding: 20px 12px 20px 20px;
    border-radius: 8px 0px 0px 8px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.03);
    height: calc(100vh - 160px);
}

.message-employee {
    padding: 20px 12px 20px 20px;
    border-radius: 0px 8px 8px 0px;
    height: calc(100vh - 160px);
}

.emp-chat-list {
    height: calc(100% - 60px);
    overflow: auto;
}

.emp-chat-box {
    padding: 12px;
    border-radius: 6px;
    margin-right: 8px;
    border-left: 4px solid #ffffff;
    cursor: pointer;
    position: relative;
}

.emp-chat-img {
    width: 56px;
    height: 56px;
    background-color: #979797;
    /* background-image: url("../../Assets/Images/profile.png"); */
    background-size: cover;
    background-position: center;
    border-radius: 6px;

}

.emp-chat-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 12px;
    cursor: pointer;
}

.emp-chat-detail p {
    color: #979797;
}


.chat-employee-list .input-group-text {
    background-color: white;
    border: 1px solid #EFEFEF;
}

.chat-employee-list .form-control {
    background-color: white;
    height: 40px;
    border-left: white !important;
    border-radius: 8px;
    border: 1px solid #EFEFEF;
    padding: 0px !important;
}

.emp-message-panel-bg {
    height: calc(100vh - 208px);
    border-radius: 12px;
    border: 1px solid #f5f4f7;
}

.chat-header {
    width: 100%;
    padding: 12px;
    background-color: rgb(245 244 247);
    border-radius: 12px 12px 0px 0px;
}


.chat-header-img {
    background-color: #979797;
    width: 40px;
    height: 40px;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
}

.chat-header-img img {
    width: 40px;
    height: 40px;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
}

.user-message-box {
    /* padding: 20px;
    overflow: auto; */
    flex-grow: 1;
    padding: 20px;
    height: calc(100% - 135px);
    overflow-y: auto;
    padding: 10px;

}

.message-input-box {
    background-color: rgb(245 244 247);
    padding: 4px;
    margin: 12px;
    display: flex;
    justify-content: space-between;
    border-radius: 8px;
}

.message-input-box textarea {
    border: none;
    width: 90%;
    background-color: rgb(245 244 247);
    flex-grow: 1;
    height: 40px;
}

.VNMHv .L704q {
    display: none !important;
}

.message-input-box textarea ::-webkit-scrollbar {
    width: 0 !important
}

.message-input-box textarea .element {
    overflow: -moz-scrollbars-none;
}

.message-input-box textarea .element {
    -ms-overflow-style: none;
}


.message-input-box button {
    width: 40px;
    height: 40px;
    background-color: #2BC8CB;
}

.message-input-box button:hover {
    width: 40px;
    height: 40px;
    background-color: #2BC8CB;
}

.message-input-box button:active {
    width: 40px;
    height: 40px;
    background-color: #2BC8CB !important;
}

.message-input-box button:focus-visible {
    width: 40px;
    height: 40px;
    background-color: #2BC8CB;
}

.message-input-box img {
    width: 24px;
    height: 24px;
}

.user-chat-message {
    background-color: #eeeeee;
    width: fit-content;
    padding: 8px;
    color: white;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    word-wrap: break-word;
    white-space: pre-line
}

.user-chat-message p {
    font-weight: 400;
    color: #343434;
    max-width: 500px;
}

.message-input-box .form-control:focus {
    background-color: rgb(245 244 247);
}

.user-chat-message-container p:nth-child(2) {
    color: #979797;
    margin-top: 4px;
    font-size: 12px;
}

.my-message-chat {
    display: flex;
    align-items: center;
    background: linear-gradient(90deg, rgba(138, 234, 236, 1) 0%, rgb(189 253 255) 100%);
    width: fit-content;
    padding: 8px;
    color: #343434;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    white-space: pre-line
}

.my-message-chat .read-message-font{
    margin-inline: 8px;
}

.my-message-box {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 10px;
}

.my-message-chat p {
    font-weight: 400;
    max-width: 500px;
    word-break: break-all
}

.day-chips-text p {
    padding: 3px 8px !important;
    background-color: #F5F4F7;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 4px;
    position: absolute;
}

.chat-loader-component {
    display: flex;
    align-items: center;
    justify-content: center;
    height: -webkit-fill-available;
}

.emp-chat-list .chat-notification-count-first {
    display: flex;
    position: absolute;
    right: 0px;
    bottom: 12px;
}

.emp-chat-list .chat-notification-count {
    display: flex;
    background-color: crimson;
    height: 24px;
    width: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    color: white;
    font-size: 14px;
}

.emp-chat-detail .current_time{
    position: absolute;
    right: 0px;
    bottom: 40px;
}