.notification-list {
    display: flex;
    padding: 12px;
    border-radius: 6px;
    cursor: pointer;
    margin-top: 10px;
    position: relative;
}

.notification-list .font-class {
    position: absolute;
    right: 12px;
    color: darkgrey;
}

.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
}

.emp-img-notification img {
    width: 56px;
    height: 56px;
    object-fit: cover;
}

.emp-img-notification {
    width: 56px;
    height: 56px;
}

.emp-detail-notification {
    margin-left: 12px;
}

.empty-notification-list{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    font-family: sans-serif;
    color: #808080;
}
