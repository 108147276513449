.add{
    color: #2BC8CB;
    font-weight: 500;
    font-style:normal;
}

.notice{
    color: #979797;
    font-weight: 400;
    font-style: normal;
}
.add-container{
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.03);
}