
.bottom-container{
    display: flex;
    align-items:center;
    justify-content: center;
    height: 30vh;
}

.entry-date{
    position: absolute;
    right: 55px;
}


.employee-date-container  .react-date-picker__wrapper{
    display: flex;
align-items: center;
border: thin solid #e6e6e6 !important;
border-radius: 8px;
height: 40px;
width: 280px;
}

.react-date-picker__inputGroup__input:invalid {
    background: none !important
}

.employee-date-container .react-date-picker{
    right: 14px;
display: block;
width: 280px;
}
.employee-date-container  .react-date-picker__inputGroup{
    padding: 0px 8px !important;
}

.employee-date-container .react-date-picker__calendar{
    width: 280px !important;
    border: none;
    border-radius: 16px;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.08);
}

@media only screen and (max-width: 576px){
    .employee-date-container .react-date-picker__calendar{
        width: 100% !important;
    }
    .react-calendar {
        width: 100% !important;
        border: none;
    }
}