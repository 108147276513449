.loader-container {
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background:transparent;
}

.spinner {
    width: 50px;
    height: 50px;
    border: 8px solid;
    border-color: #2BC8CB transparent #2BC8CB transparent;
    border-radius: 100%;
    animation: spin-anim 1.5s linear infinite;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}