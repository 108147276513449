.change-password-input{
    padding-inline: 20px;
    width: 100%;
    position: relative;
}

.change-password-input .svg-inline--fa {
    position: absolute;
    right: 20px;
    top: 20px;
}

.change-button-container{
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    padding-inline: 20px;
    width: 100%;
}

.change-button-container button{
    width: 100%;
    height: 45px;
    background-color: #2BC8CB;
    border: none;
    text-transform: uppercase;
}

.change-button-container button:hover{
    background-color: #2BC8CB !important;
    border: none
}