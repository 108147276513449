.file-container{
    padding: 8px;
  
}

.file-container input{
    height: 24px;
    width: 24px;
    position: absolute;
    opacity: 0;
    cursor: pointer;  
}

.empty-message-container{
    display: flex;
    align-items: center;
    justify-content: center;
}

.empty-message-container div{
    background-color: #F0FBFB;
    padding: 50px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.chat-loader-container{
    height: calc(100% - 135px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative !important;
    background: transparent;
    width: 100% !important;
}

.text-area-size{
    resize: none;
}