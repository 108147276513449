.upload-sec {
    position: relative;
}

.upload-profile {
    width: 120px;
    height: 120px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-profile input {
    width: 120px;
    height: 120px;
    opacity: 0;
    position: absolute;
    left: 0px;
    top: 0px;
}

.upload-profile-img {
    position: absolute;
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
    /* z-index: 99; */
}

.upload-profile .uploaded-img {
    object-fit: cover;
    height: 120px;
}

.upload-icon {
    width: 24px;
    height: 24px;
}

.uploaded-img-set {
    z-index: 9999;
}

.font-style {
    position: absolute;
    right: 72%;
    top: 10px;
    color: red;
}