.btn-class {
  position: absolute;
  top: 70px;
  right: 0px;
  background-color: transparent !important;
  border: none !important;
}

.btn-class :hover {
  background-color: transparent !important;
  border: none !important;
}

.btn-class img {
  height: 38px;
  /* background-color: white; */
}

.react-daterange-picker__wrapper {
  display: flex;
  align-items: center;
  border: thin solid #e6e6e6 !important;
  border-radius: 8px;
  height: 50px;
  width: 280px;
  justify-content: space-around;
}

.react-daterange-picker__inputGroup {
  flex-grow: initial;
}

.react-daterange-picker {
  right: 14px;
  display: block;
  width: 280px;
}

.react-calendar {
  width: 280px;
  border: none;
}

.react-daterange-picker__calendar {
  width: 280px ;
  border: none;
  border-radius: 16px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.08);
}


.react-daterange-picker__inputGroup__input:invalid {
  background: none
}

.user-location-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 260px);
 width: calc(100vw - 615px);
}


.timeMarker {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 9999;
  right: 70px;
  top: 15px;

}

.timeMarkerText {
  font-weight: bold;
}

.start-marker {
  display: flex;
  align-items: center;
  padding-inline: 10px;
}

.start-marker h6 {
  padding-top: 11px;
}

.start-marker img {
  width: 23px;
  height: 23px;
}

.location-pin-section {
  display: flex;
  margin-top: 15px;
}

.location-start-stop {
  background-color: #2BC8CB !important;
  border: none !important;
  align-items: center;
  justify-content: center;
  height: 40px;
}
.start-marker img{
  margin-right: 4px;
}

@media only screen and (max-width: 1024px){
  .map-wrapper .map-iframe{
    width: 98% !important;
    height: 100%;
    margin-top: 16px;
  }
  
}
@media only screen and (max-width: 576px){
  .search-class {
    margin-block: 10px;
  }

  .map-wrapper .map-iframe{
    width: 100%;
    height: 100%;
  }
  .location-pin-section {
    display: flex;
    margin-top: 15px;
    flex-direction: column;
}
.employee-map-section{
  height: calc(100vh - 50px) !important;
}
.user-location-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 260px);
 width:auto;
}
}

 

