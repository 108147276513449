.input-container{
    margin-top: 20px;
    width: 100%;
}


.inner-container{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.inner-container h6{
    cursor: pointer;
}
.otp-field-input > div {
    display: flex;
    justify-content: space-between;
}
.otp-field-input > div input{ 
    width: 18% !important;

}

.resend-otp{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.resend-button{
    background-color: transparent !important;
    color: rgb(128, 128, 128) !important;
    border-color: transparent !important;
    text-decoration: underline !important;
}

.resend-button :hover{
    background-color: transparent !important;
    color: black;
    border-color: transparent !important;
    text-decoration: underline !important;
}