.add{
    color: #2BC8CB;
    font-weight: 500;
    font-style:normal;
}

.employee-date-container{
   padding: 12px;
}

.employee-date-container input{
    height: 40px;
    width: 250px;
}